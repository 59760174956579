import { PricePlanName } from "@internal/rest/generated/schemas";
import { useFormContext } from "react-hook-form";
import { isBranded } from "screens/create-deal/utils";

import { FormFields } from "../../utils";

interface Args {
  onPricePlanChange: (pricePlan: PricePlanName) => void;
}

export function usePricePlans(args: Args) {
  const formContext = useFormContext();

  function onSelectPricePlan(pricePlan: PricePlanName) {
    if (!isBranded(pricePlan)) {
      formContext.clearErrors(FormFields.EntityLegalName);
    }

    args.onPricePlanChange(pricePlan);
  }

  return {
    onSelectPricePlan,
  };
}
