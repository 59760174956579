import {
  getDealsGetDraftQueryKey,
  useDealsUpdateDraft,
} from "@internal/rest/generated/queries/drafts";
import { useQueryClient } from "@tanstack/react-query";
import { useDialog } from "components/main/Dialog/utils";
import { useContext } from "react";
import { handleResourceEffect } from "utility/async";

import { CreateDealContext } from "../../context";
import { useCarryStepState } from "./state";
import { FormValues, computeFeesPayload } from "./utils";

export enum FormFields {
  DefaultCarry = "default_carry",
  SyndLeadsCommitment = "syndicate_leads_commitment",
  PlatformFeeCovered = "platform_fee_covered_by",
}

const watchedFields = [FormFields.DefaultCarry, FormFields.PlatformFeeCovered];

export function useCarryStep() {
  const queryClient = useQueryClient();
  const { onOpen: onOpenModal } = useDialog();
  const { draftId, onNextStep } = useContext(CreateDealContext);

  const {
    onFieldsChange,
    onAddRecipient,
    onRemoveRecipient,
    onUpdateRecipientShare,
    toggleSubmitting,
    state,
  } = useCarryStepState();

  const { mutateAsync: updateDraft } = useDealsUpdateDraft();

  async function onSubmit(values: FormValues) {
    if (!state.carryRecipients.length && Number(state.defaultCarry) > 0) return;

    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        await updateDraft({
          dealDraftId: draftId,
          data: {
            fees: {
              ...computeFeesPayload({
                values,
                recipients: state.carryRecipients,
              }),
            },
          },
        });

        await queryClient.resetQueries({
          queryKey: getDealsGetDraftQueryKey(draftId),
          exact: true,
        });

        onNextStep();
      },
      error: {
        title: "An unexpected draft problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  return {
    initialValues: state.initialValues,
    onSubmit,
    baseCurrency: state.currency,
    watchedFields,
    onFieldsChange,
    onOpenModal,
    onAddRecipient,
    onAddPercent: onUpdateRecipientShare,
    onRemoveRecipient,
    carryRecipients: state.carryRecipients,
    arePercents100: state.arePercents100,
    isSubmitting: state.isSubmitting,
    formValues: {
      defaultCarry: state.defaultCarry,
      platformFeesPayee: state.platformFeesPayee,
    },
  };
}
