import { SeisCheckboxValues } from "../../utils";

export enum SEISValues {
  SEIS = "seis",
  EIS = "eis",
}

interface ComputeTaxFormValueArgs {
  eis: boolean;
  seis: boolean;
}

export function computeTaxFormValue({ eis, seis }: ComputeTaxFormValueArgs) {
  return [
    eis ? SEISValues.EIS : undefined,
    seis ? SEISValues.SEIS : undefined,
  ].filter(Boolean) as SeisCheckboxValues[];
}
