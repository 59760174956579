import { Box, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { FormLabel } from "components/forms/Form/FormLabel/FormLabel";
import { Link } from "components/interaction/Link";
import { RichText } from "components/interaction/RichText";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";

import { StepSubmit } from "../../components/StepSubmit/StepSubmit";
import { DeckField } from "./components/DeckField/DeckField";
import { InvestmentAgreementField } from "./components/InvestmentAgreementField/InvestmentAgreementField";
import { useMemoMaterialsStep } from "./useMemoMaterialsStep";
import { MemoMaterialsSchema, getNameAndLabel } from "./utils";

export const inputMaxWidth = "64!";

export function MemoMaterialsStep() {
  const {
    initialValues,
    onSubmit,
    onEditMemo,
    missingMemo,
    currentMemo,
    isSubmitting,
    isDocUploadEnabled,
  } = useMemoMaterialsStep();

  return (
    <Form
      onSubmit={onSubmit}
      config={{
        schema: MemoMaterialsSchema,
        defaultValues: initialValues.form,
      }}
    >
      <VStack alignItems="flex-start" gap="3.5rem" w="full">
        <Box w="full">
          <FormLabel fontSize="md" mb="0!">
            Memo
          </FormLabel>
          <Typography variant="subtext" color="grey.gunsmoke" mb="m">
            Describe what the deal is about.{" "}
            <Dialog title="Memo preview" styling={{ width: "60vw" }}>
              <Dialog.Trigger>
                <Button
                  variant="link"
                  mb="xs"
                  data-testid="memo-preview-button"
                  height="auto"
                >
                  Click here to preview
                </Button>
              </Dialog.Trigger>
              <Dialog.Content>
                <Box overflow="auto" pr="m" pl="s">
                  <RichText type="viewer" initialContent={currentMemo} />
                </Box>
              </Dialog.Content>
            </Dialog>{" "}
            how the memo will look on the deal page.
          </Typography>

          <RichText
            type="editor"
            initialContent={initialValues.memo}
            onChange={onEditMemo}
          />

          {missingMemo ? (
            <Typography variant="body" color="yellow.vesuvius" mt="s">
              Please write a memo for the deal
            </Typography>
          ) : null}
        </Box>

        {isDocUploadEnabled ? (
          <>
            <DeckField />
            <InvestmentAgreementField />
          </>
        ) : (
          <>
            <Form.URLField
              {...getNameAndLabel("investment_agreement")}
              width={["full", inputMaxWidth]}
              subLabel={
                <>
                  Link to a Google Drive or similar.
                  <br />
                  Please ensure the document is not blank and can be viewed by
                  anyone who has the link
                </>
              }
            />

            <Form.URLField
              {...getNameAndLabel("pitch_deck")}
              width={["full", inputMaxWidth]}
              subLabel={
                <>
                  Link to a Google Drive or similar.
                  <br />
                  Please ensure the document is not blank and can be viewed by
                  anyone who has the link
                </>
              }
            />

            <Form.URLEmbed
              {...getNameAndLabel("pitch_deck_embed")}
              width={["full", inputMaxWidth]}
              subLabel={
                <Typography variant="subtext" color="grey.gunsmoke">
                  Link to the embeddable form of your deck.{" "}
                  <Link
                    href="https://help.joinodin.com/hc/en-gb/articles/25326486288273-Drafting-a-primary-deal"
                    isExternal
                  >
                    Learn more
                  </Link>
                  .
                </Typography>
              }
            />
          </>
        )}
      </VStack>
      <Box pt="2rem">
        <StepSubmit isDisabled={missingMemo} isLoading={isSubmitting} />
      </Box>
    </Form>
  );
}
