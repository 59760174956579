import { Box, VStack } from "@@panda/jsx";
import { Card } from "components/display/Card";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { useAuth } from "services/auth/AuthProvider";
import { Flags, useFeatureFlag } from "services/feature-flags";

import { FormTermsSignedOn } from "./FormTermsSignedOn";
import { TermsFounder } from "./TermsFounder";
import { TermsSyndicate } from "./TermsSyndicate";
import { FormValues } from "./helpers";

interface Props {
  onSubmit: (signature: string) => void;
  isSubmitting: boolean;
  variant: "syndicate" | "founder";
}

export function SubmitTermsModal({ onSubmit, isSubmitting, variant }: Props) {
  const { account } = useAuth();
  const instantDealsFF = useFeatureFlag(Flags.INSTANT_DEALS);
  const { first_name = "", last_name = "" } = account || {};

  return (
    <Dialog title="Review terms">
      <Dialog.Trigger>
        <Button mt="xl" isLoading={isSubmitting}>
          {instantDealsFF ? "Publish" : "Submit for review"}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Form onSubmit={(data: FormValues) => onSubmit(data.signature)}>
          <VStack
            alignItems="flex-start"
            gap="m"
            data-testid="submit-draft-terms-modal"
          >
            <Card
              width="full"
              textAlign="left"
              py="l"
              px="m"
              data-testid="terms-card"
              withBorder
            >
              {variant === "syndicate" ? <TermsSyndicate /> : <TermsFounder />}
            </Card>
            <Box w="full">
              <Form.Signature
                firstName={first_name}
                lastName={last_name}
                name="signature"
                label="Signature*"
              />
              <FormTermsSignedOn />
              <Typography variant="body" color="grey.gunsmoke" mt="l">
                By clicking submit, I accept these terms and conditions.
              </Typography>
            </Box>
            <Form.Submit isLoading={isSubmitting}>
              Accept Syndicate Terms & Submit
            </Form.Submit>
          </VStack>
        </Form>
      </Dialog.Content>
    </Dialog>
  );
}
