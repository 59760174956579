import { Link } from "components/interaction/Link";
import { Styled } from "components/styled";

import { SYND_TERMS_URL, USER_AGREEMENT_URL } from "./helpers";

export function TermsFounder() {
  return (
    <Styled.ul display="flex" flexDir="column" alignItems="flex-start" gap="m">
      <Styled.li listStyleType="disc" ml="m">
        I acknowledge, and agree as the Founder or Syndicate Lead (as defined
        therein) to be bound by, the terms of the relevant Deal Sheet, the{" "}
        <Link href={USER_AGREEMENT_URL} isExternal>
          User Agreement
        </Link>{" "}
        and the{" "}
        <Link href={SYND_TERMS_URL} isExternal>
          Syndicate Terms
        </Link>{" "}
        in relation to each Deal which I arrange using the Platform.
      </Styled.li>

      <Styled.li listStyleType="disc" ml="m">
        I have communicated, or will communicate, the opportunity to participate
        in each Deal which I arrange using the Platform to the members of my
        Syndicate solely in accordance with applicable law and regulation,
        including adhering to all necessary restrictions and stipulations
        relating to the communications of financial promotions.
      </Styled.li>

      <Styled.li listStyleType="disc" ml="m">
        I have sought such legal advice as I require regarding my own activities
        and my regulatory status such that I am confident that I am able to act
        as a Syndicate Lead while remaining compliant with all applicable law
        and regulation.
      </Styled.li>

      <Styled.li listStyleType="disc" ml="m">
        I understand that the Administration Fee payable to Odin is an estimate
        based on the expected total investment and that the final prorated fee
        per investor will be calculated as per the Syndicate Terms and may be
        slightly more or less than the amount stated above.
      </Styled.li>
    </Styled.ul>
  );
}
