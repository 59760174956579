import {
  PricePlanName,
  Syndicate,
  SyndicateListItem,
} from "@internal/rest/generated/schemas";
import { isBranded } from "screens/create-deal/utils";

export enum FormLabels {
  Syndicate = "Select syndicate",
  SyndicateName = "Syndicate name",
  EntityLegalName = "Entity legal name",
}

export enum FormFields {
  Syndicate = "syndicate",
  SyndicateName = "syndicate_name",
  EntityLegalName = "entity_legal_name",
}

export const watchedFields = [FormFields.Syndicate, FormFields.EntityLegalName];

export interface FormValues {
  [FormFields.Syndicate]: string;
  [FormFields.SyndicateName]: string;
  [FormFields.EntityLegalName]: string;
}

export const CREATE_NEW_SYNDICATE_OPTION = "create-new-syndicate";

interface ShouldUpdateEntityLegalNameArgs {
  pricePlan: PricePlanName;
  syndicate: Syndicate | undefined;
  values: FormValues;
}

export function shouldUpdateSyndicate({
  pricePlan,
  syndicate,
  values,
}: ShouldUpdateEntityLegalNameArgs): boolean {
  if (!isBranded(pricePlan)) return false;
  if (!syndicate) return false;

  // Don't update if legal name is empty
  if (!values[FormFields.EntityLegalName]) return false;

  // Don't update if the name doesn't change
  if (syndicate.legal_name === values[FormFields.EntityLegalName]) return false;

  return true;
}

interface ShouldShowEntityLegalNameFieldArgs {
  pricePlan: PricePlanName;
  syndicateId: string;
  syndicate: Syndicate | null;
}

export function shouldShowEntityLegalNameField({
  pricePlan,
  syndicateId,
  syndicate,
}: ShouldShowEntityLegalNameFieldArgs): boolean {
  const isCreatingBrandedSyndicate =
    syndicateId === CREATE_NEW_SYNDICATE_OPTION && isBranded(pricePlan);

  const withSyndicate = Boolean(syndicate);

  const isSwitchingToBranded =
    withSyndicate &&
    isBranded(pricePlan) &&
    syndicate?.legal_name_mutability === "mutable";

  if (isCreatingBrandedSyndicate) return true;
  if (isSwitchingToBranded) return true;

  return false;
}

export function getSelectedSyndicate(
  syndicates: SyndicateListItem[] | undefined,
  syndicateId: string
) {
  return syndicates?.find((s) => s.id === syndicateId);
}

export function isCurrentPricing(plan: PricePlanName) {
  switch (plan) {
    case "base_2023":
    case "core_2023":
    case "headliner_2023":
      return true;
    default:
      return false;
  }
}
