import { css } from "@@panda/css";
import { Box, Flex, HStack } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { getCurrencySign } from "@internal/utils/currency";
import { Radio } from "components/display/Radio";
import { Typography } from "components/display/Typography/Typography";

import { PricePlanCardProps } from "../PricePlanCard";
import {
  PricePlanColors,
  PricePlanValues,
  getLabelFromPricePlan,
} from "../utils";

type Props = Omit<PricePlanCardProps, "variant">;

/**
 * @todo This variant of the Price Plan Card is currently used in the Create Deal Flow.
 *       Once we move Create Syndicate out of there to the Create Syndicate Flow (which)
 *       uses the wide variant, we can delete this component
 * @note Legacy
 */
export function NarrowPricePlanCard(props: Props) {
  const {
    // NOTE: We currently only display prices for GBP as that's our main market.
    // Other currencies are handled manually, by the sales and operations teams.
    currency = BaseCurrency.gbp,
    disabled = false,
    onClick,
    pricePlan = PricePlanName.core_2023,
    readonly = false,
    selected = false,
    ...rest
  } = props;

  const actualCurrency = currency === "usdc" ? "usd" : currency;
  const pricePlanValues = PricePlanValues[pricePlan];
  const pricePlanPrices = pricePlanValues.prices[actualCurrency];
  const currencySymbol = getCurrencySign(currency);

  return (
    <Box
      data-testid={`price-plan-item-${pricePlan}`}
      aria-selected={selected}
      onClick={
        onClick && !disabled && !readonly ? () => onClick(pricePlan) : undefined
      }
      cursor={disabled ? "not-allowed" : "pointer"}
      pointerEvents={disabled ? "none" : "auto"}
      p="3"
      borderWidth="2px"
      borderStyle="solid"
      borderColor="grey.minecraft"
      bgColor="grey.woodsmoke"
      borderRadius="md"
      height="full"
      transition="all 0.25s"
      className={css({
        _selected: {
          borderColor: "brand.yamcha",
          bgColor: "grey.bunker",
        },
      })}
      {...rest}
    >
      <Flex justifyContent="space-between" mb="2">
        <Typography
          variant="subtext"
          data-testid={`price-plan-item-plan-name-${pricePlan}`}
          letterSpacing="0.11em"
          textTransform="uppercase"
          fontWeight="medium"
          style={{ color: token(PricePlanColors[pricePlan]) }}
        >
          {getLabelFromPricePlan(pricePlan)}
        </Typography>
        {!readonly && (
          <Radio
            name={pricePlan}
            data-testid={`price-plan-item-radio-${pricePlan}`}
            checked={selected}
            value={pricePlan}
            hideLabel
          />
        )}
      </Flex>
      <HStack
        justifyContent="space-between"
        alignItems="flex-end"
        mb="1"
        w="full"
        gap="0"
      >
        <Typography
          variant="body"
          gap="0.5"
          fontFamily="body"
          fontWeight="medium"
          mr="1"
        >
          <Typography
            variant="body"
            data-testid={`price-plan-item-percentage-${pricePlan}`}
            as="span"
            fontSize="1.125rem"
          >
            {pricePlanValues.plusPercentageFundsRaised}
          </Typography>
          <Typography variant="subtext" as="span">
            %&nbsp;per&nbsp;deal
          </Typography>
        </Typography>
        <Box
          display="flex"
          gap="0.5"
          alignItems="baseline"
          flexDirection="row"
          fontFamily="body"
          fontWeight="medium"
          mr="1"
        >
          <Typography variant="subtext" pt="0.5">
            {currencySymbol}
          </Typography>
          <HStack alignItems="flex-end" gap="0">
            <Typography
              variant="body"
              data-testid={`price-plan-item-subscription-${pricePlan}`}
              fontSize="md"
            >
              {pricePlanPrices.price}
            </Typography>
            <Typography variant="subtext" pb="0.5">
              /year
            </Typography>
          </HStack>
        </Box>
      </HStack>
      <Typography
        variant="subtext"
        data-testid={`price-plan-item-min-cap-${pricePlan}`}
        color="grey.gunsmoke"
        mb={pricePlanPrices.hint ? "0" : "2"}
      >
        {currencySymbol}
        {pricePlanPrices.floor} min. fee ・ {currencySymbol}
        {pricePlanPrices.cappedAt} cap
      </Typography>
      {pricePlanPrices.hint ? (
        <Typography variant="body" color="brand.fauve" mb="2">
          {pricePlanPrices.hint}
        </Typography>
      ) : null}
      {pricePlanValues.plusPercentageFundsRaised === null ? null : (
        <Typography
          variant="body"
          data-testid={`price-plan-item-description-${pricePlan}`}
          fontFamily="body"
        >
          {pricePlanValues.description || ""}
        </Typography>
      )}
    </Box>
  );
}
