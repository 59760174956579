import { useContext } from "react";
import { CreateDealContext } from "screens/create-deal/context";

export function usePlatformAndAdditionalFees() {
  const { currentDraft } = useContext(CreateDealContext);

  return {
    selectedPricePlan: currentDraft.pricing_plan,
    currency: currentDraft.terms?.base_currency,
  };
}
