import { PricePlanName } from "@internal/rest/generated/schemas";

interface IsUpgradedPricePlanArgs {
  plan: PricePlanName;
}

export function isUpgradedPricePlan({ plan }: IsUpgradedPricePlanArgs) {
  if (
    plan === PricePlanName.core_2023 ||
    plan === PricePlanName.headliner_2023
  ) {
    return true;
  }

  return false;
}

export const activeSyndicatePricePlans = [
  PricePlanName.base_2023,
  PricePlanName.core_2023,
  PricePlanName.headliner_2023,
];

export const activeFounderPricePlan = [PricePlanName.founder_2023_09];

export const validateActivePricePlan = (
  plan: PricePlanName
): { ok: true; plan: ActivePricePlan } | { ok: false; plan: PricePlanName } => {
  const yes =
    activeSyndicatePricePlans.includes(plan) ||
    activeFounderPricePlan.includes(plan);

  if (yes) {
    return {
      ok: true,
      plan: plan as ActivePricePlan,
    };
  }

  return {
    ok: false,
    plan: plan,
  };
};

export type ActivePricePlan =
  | (typeof activeSyndicatePricePlans)[number]
  | (typeof activeFounderPricePlan)[number];
