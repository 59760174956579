import { Box, styled } from "@@panda/jsx";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { PricePlanCard } from "components/display/PricePlanCard/PricePlanCard";
import { Typography } from "components/display/Typography/Typography";
import { Link } from "components/interaction/Link";

import { CREATE_NEW_SYNDICATE_OPTION } from "../../utils";
import { usePricePlans } from "./usePricePlans";
import { activeSyndicatePricePlans } from "./utils";

interface Props {
  active: PricePlanName;
  syndicatePlan: PricePlanName;
  currency: BaseCurrency;
  onPricePlanChange: (pricePlan: PricePlanName) => void;
  currentSyndicateId: string;
  isSubscribed: boolean;
}

export function PricePlans({
  active,
  onPricePlanChange,
  syndicatePlan,
  isSubscribed,
  currentSyndicateId,
}: Props) {
  const { onSelectPricePlan } = usePricePlans({
    onPricePlanChange,
  });

  const isCreatingSyndicate =
    currentSyndicateId === CREATE_NEW_SYNDICATE_OPTION;

  const isUpgraded = isSubscribed && !isCreatingSyndicate;

  return (
    <Box mb="2.5rem">
      <Box mt="xl" mb="0">
        <styled.p fontSize="sm" fontWeight={500} color="grey.iron" mb="m">
          Subscription tier
        </styled.p>
      </Box>
      {isUpgraded ? (
        <Box w="18.75rem">
          <PricePlanCard
            onClick={onSelectPricePlan}
            pricePlan={syndicatePlan}
            selected={true}
            readonly
          />
        </Box>
      ) : (
        <Box mt="m">
          <Box
            display="grid"
            gridTemplateColumns={{
              base: "repeat(1, 1fr)",
              xl: "repeat(3, minmax(0, 1fr))",
            }}
            gap="1"
          >
            {activeSyndicatePricePlans.map((plan) => (
              <PricePlanCard
                key={plan}
                onClick={onSelectPricePlan}
                pricePlan={plan}
                mr="2"
                selected={active === plan}
              />
            ))}
          </Box>
        </Box>
      )}
      {isUpgraded ? (
        <Typography variant="body" mt="4">
          <Link
            href="mailto:hello@joinodin.com?subject=Change subscription tier"
            isExternal
          >
            Contact us
          </Link>{" "}
          to discuss changes to your price plan.
        </Typography>
      ) : null}
    </Box>
  );
}
