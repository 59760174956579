import { BoxProps } from "@@panda/jsx";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";

import { LegacyPricePlanCard } from "./components/LegacyPricePlanCard";
import { NarrowPricePlanCard } from "./components/NarrowPricePlanCard";
import { isLegacyPricePlan } from "./utils";

export interface PricePlanCardProps extends Omit<BoxProps, "onClick"> {
  currency?: BaseCurrency;
  disabled?: boolean;
  onClick?: (plan: PricePlanName) => void;
  pricePlan?: PricePlanName;
  readonly?: boolean;
  selected?: boolean;
}

export function PricePlanCard(props: PricePlanCardProps) {
  if (isLegacyPricePlan(props.pricePlan)) {
    return <LegacyPricePlanCard {...props} />;
  }

  return <NarrowPricePlanCard {...props} />;
}
