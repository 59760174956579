import { css, cx } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { FileUpload } from "components/interaction/FileUpload/FileUpload";
import { Link } from "components/interaction/Link";
import { Variants, tabsRecipe } from "components/main/Tabs/styles";
import { useState } from "react";

import { inputMaxWidth } from "../../MemoMaterialsStep";
import { getNameAndLabel } from "../../utils";

const tabsStyles = tabsRecipe({ visual: Variants.Compact });

const pillButtonStyles = cx(
  tabsStyles.tab,
  css({
    cursor: "pointer",
    borderColor: "#383838ab!",
  })
);

export function DeckField() {
  const [mode, setMode] = useState<"text" | "file">("file");
  const fileSubLabel = <>File must be in PDF format.</>;

  return (
    <Box w="full">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        w="full"
        mb="s"
      >
        <Typography variant="body" fontSize="md">
          Pitch deck
        </Typography>
        <HStack justifyContent="flex-start" alignItems="center" w="auto!">
          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "file"}
            onClick={() => setMode((m) => (m === "file" ? "text" : "file"))}
            flexShrink={0}
          >
            File
          </Box>
          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "text"}
            onClick={() => setMode((m) => (m === "file" ? "text" : "file"))}
            flexShrink={0}
          >
            Link
          </Box>
        </HStack>
      </HStack>

      {mode === "text" ? (
        <VStack alignItems="flex-start" gap="m" w="full">
          <Form.URLField
            {...getNameAndLabel("pitch_deck")}
            label="Public link*"
            width={["full", inputMaxWidth]}
            subLabel={
              <>
                Please ensure the document is not blank and can be viewed by
                anyone who has the link
              </>
            }
          />

          <Form.URLEmbed
            {...getNameAndLabel("pitch_deck_embed")}
            label="Embedded deck URL"
            width={["full", inputMaxWidth]}
            subLabel={
              <Typography variant="subtext" color="grey.gunsmoke">
                Link to the embeddable form of your deck.{" "}
                <Link
                  href="https://help.joinodin.com/hc/en-gb/articles/25326486288273-Drafting-a-primary-deal"
                  isExternal
                >
                  Learn more
                </Link>
                .
              </Typography>
            }
          />
        </VStack>
      ) : (
        <FileUpload
          {...getNameAndLabel("pitch_deck")}
          label=""
          subLabel={fileSubLabel}
          singleFileMode
        />
      )}
    </Box>
  );
}
