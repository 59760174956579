import {
  BaseCurrency,
  Draft,
  DraftTerms,
  DraftTermsEquityShareClass,
  InvestmentRound,
  InvestmentType,
  ProxyVotingTarget,
  ValuationCapType,
} from "@internal/rest/generated/schemas";
import { handleInitialAmount } from "screens/create-deal/utils";
import { isoDateStringToYYYYMMDD } from "utility/dates";
import { DEFAULT_MIN_COMMITMENT } from "utility/deals";

import { computeRoundTypeDisplayValue } from "./components/RoundField/utils";
import { computeTaxFormValue } from "./components/SEISFields/utils";

export type SeisCheckboxValues = "seis" | "eis";

export type ValuationCapFormType = "none" | ValuationCapType;

export enum FormFields {
  Currency = "base_currency",
  Round = "round",
  SEIS = "seis",
  Instrument = "investment_type",
  ShareClass = "equity_share_class",
  ShareClassCommon = "equity_share_class_common",
  SharePrice = "equity_share_price",
  PreMoney = "pre_money_valuation",
  ValuationCap = "valuation_cap",
  ValuationCapType = "valuation_cap_type",
  Discount = "discount",
  TotalRound = "total_round_size",
  Allocation = "allocation",
  ProxyVoting = "proxy_voting_rights",
  TargetClose = "target_close",
  MinCommitment = "minimum_commitment",
}

export interface FormValues {
  [FormFields.Currency]: BaseCurrency;
  [FormFields.Round]: string;
  [FormFields.SEIS]: SeisCheckboxValues[];
  [FormFields.Instrument]: InvestmentType;
  [FormFields.ShareClass]?: DraftTermsEquityShareClass;
  [FormFields.ShareClassCommon]?: DraftTermsEquityShareClass;
  [FormFields.SharePrice]?: string;
  [FormFields.PreMoney]?: string;
  [FormFields.Discount]?: string;
  [FormFields.ValuationCap]?: string;
  [FormFields.ValuationCapType]?: ValuationCapFormType;
  [FormFields.TotalRound]: string;
  [FormFields.Allocation]: string;
  [FormFields.MinCommitment]: string;
  [FormFields.TargetClose]: string;
  [FormFields.ProxyVoting]: ProxyVotingTarget;
}

export enum FormLabels {
  // Base fields
  Currency = "Base currency*",
  SEIS = "Will investors be able to claim S/EIS relief?",
  Round = "Round*",
  Instrument = "Investment instrument*",
  Allocation = "SPV allocation*",
  MinCommitment = "Minimum commitment",
  TotalRound = "Total round size*",
  ProxyVoting = "Who handles voting?*",
  // Equity fields
  SharePrice = "Share price",
  ShareClass = "Share class",
  PreMoney = "Pre-money valuation",
  // Non-equity fields
  ValuationCap = "Valuation cap",
  ValuationCapType = "Valuation cap type",
  Discount = "Discount",
  // Other
  SeisId = "form-checkbox-group-seis",
  TargetClose = "Target close date",
}

type SeisValues = "seis" | "eis";

interface MapSeisValuesArgs {
  data: SeisValues[] | undefined;
}

export function mapSeisValues({ data }: MapSeisValuesArgs) {
  if (!data || !data.length) return {};

  return data.reduce((acc, val) => {
    return val === "seis"
      ? { ...acc, is_seis_eligible: true }
      : val === "eis"
        ? { ...acc, is_eis_eligible: true }
        : acc;
  }, {});
}

function getEquityPayload(data: FormValues, withSeis: boolean) {
  return {
    // Base fields
    [FormFields.Currency]: data[FormFields.Currency],
    [FormFields.MinCommitment]: data[FormFields.MinCommitment]
      ? Number(data[FormFields.MinCommitment])
      : DEFAULT_MIN_COMMITMENT,
    [FormFields.ProxyVoting]: data[FormFields.ProxyVoting],
    [FormFields.Round]: data[FormFields.Round] as InvestmentRound,
    [FormFields.Instrument]: data[FormFields.Instrument],
    [FormFields.TotalRound]: Number(data[FormFields.TotalRound]),
    [FormFields.Allocation]: Number(data[FormFields.Allocation]),
    // Equity fields
    [FormFields.ShareClass]: withSeis
      ? (data[FormFields.ShareClassCommon] as DraftTermsEquityShareClass)
      : (data[FormFields.ShareClass] as DraftTermsEquityShareClass),
    [FormFields.SharePrice]: Number(data[FormFields.SharePrice]),
    [FormFields.PreMoney]: Number(data[FormFields.PreMoney]),
  };
}

function getNonEquityPayload(data: FormValues) {
  return {
    // Base fields
    [FormFields.Currency]: data[FormFields.Currency],
    [FormFields.MinCommitment]: data[FormFields.MinCommitment]
      ? Number(data[FormFields.MinCommitment])
      : DEFAULT_MIN_COMMITMENT,
    [FormFields.ProxyVoting]: data[FormFields.ProxyVoting],
    [FormFields.Round]: data[FormFields.Round] as InvestmentRound,
    [FormFields.Instrument]: data[FormFields.Instrument],
    [FormFields.TotalRound]: Number(data[FormFields.TotalRound]),
    [FormFields.Allocation]: Number(data[FormFields.Allocation]),
    // Non-equity fields
    ...(data[FormFields.Discount]
      ? { [FormFields.Discount]: Number(data[FormFields.Discount]) }
      : null),
    [FormFields.ValuationCapType]: data[FormFields.ValuationCapType] || "none",
    ...(data[FormFields.ValuationCapType] !== "none"
      ? {
          [FormFields.ValuationCap]: Number(data[FormFields.ValuationCap]),
        }
      : null),
  };
}

function getSecondaryPayload(data: FormValues) {
  return {
    // Base fields
    [FormFields.Currency]: data[FormFields.Currency],
    [FormFields.MinCommitment]: data[FormFields.MinCommitment]
      ? Number(data[FormFields.MinCommitment])
      : DEFAULT_MIN_COMMITMENT,
    [FormFields.ProxyVoting]: data[FormFields.ProxyVoting],
    [FormFields.Allocation]: Number(data[FormFields.Allocation]),
    // Secondary fields
    [FormFields.ShareClass]: data[
      FormFields.ShareClass
    ] as DraftTermsEquityShareClass,
    [FormFields.SharePrice]: Number(data[FormFields.SharePrice]),
  };
}

export function computeFormValuesToDealTerms(
  data: FormValues,
  isSecondary: boolean,
  isFounder: boolean,
  customTerms: string
): DraftTerms {
  const { investment_type } = data;
  const isEquity = investment_type === "equity";
  const withSeis = data.seis && data.seis.length > 0;

  const seis = withSeis
    ? mapSeisValues({
        data: data.seis,
      })
    : null;

  const draftCustomTerms = !isFounder ? { custom_terms: customTerms } : null;

  if (isSecondary) {
    return {
      ...getSecondaryPayload(data),
      ...draftCustomTerms,
    };
  }

  if (isEquity) {
    return {
      ...getEquityPayload(data, withSeis),
      ...seis,
      ...draftCustomTerms,
    };
  }

  return {
    ...getNonEquityPayload(data),
    ...seis,
    ...draftCustomTerms,
  };
}

export const getDefaultFormValues = (currentDraft: Draft) => {
  const savedInvestmentType =
    currentDraft.terms?.investment_type ?? InvestmentType.equity;

  const savedCurrency = currentDraft.terms?.base_currency ?? BaseCurrency.gbp;

  const SEIS = currentDraft.terms?.is_seis_eligible;
  const EIS = currentDraft.terms?.is_eis_eligible;

  const SEISdefaultValue = computeTaxFormValue({
    eis: Boolean(EIS),
    seis: Boolean(SEIS),
  });

  const defaultValues: FormValues = {
    [FormFields.Currency]: savedCurrency,
    [FormFields.Round]: computeRoundTypeDisplayValue(currentDraft.terms?.round),
    [FormFields.Instrument]: savedInvestmentType,
    [FormFields.SEIS]: SEISdefaultValue,
    [FormFields.ShareClass]:
      currentDraft.terms?.equity_share_class ??
      DraftTermsEquityShareClass.common,
    [FormFields.ShareClassCommon]: DraftTermsEquityShareClass.common,
    [FormFields.SharePrice]: handleInitialAmount(
      currentDraft.terms?.equity_share_price
    ),
    [FormFields.PreMoney]: handleInitialAmount(
      currentDraft.terms?.pre_money_valuation
    ),
    [FormFields.ValuationCapType]:
      currentDraft.terms?.valuation_cap_type ?? ValuationCapType.none,
    [FormFields.ValuationCap]: handleInitialAmount(
      currentDraft.terms?.valuation_cap
    ),
    [FormFields.Discount]: handleInitialAmount(currentDraft.terms?.discount),
    [FormFields.TotalRound]: handleInitialAmount(
      currentDraft.terms?.total_round_size
    ),
    [FormFields.Allocation]: handleInitialAmount(
      currentDraft.terms?.allocation
    ),
    [FormFields.MinCommitment]: handleInitialAmount(
      currentDraft.terms?.minimum_commitment
    ),
    [FormFields.TargetClose]: isoDateStringToYYYYMMDD(
      currentDraft.target_closing_at,
      { fallback: "" }
    ),
    [FormFields.ProxyVoting]:
      currentDraft.terms?.proxy_voting_rights ??
      ProxyVotingTarget.syndicate_lead,
  };

  return defaultValues;
};
