import { Box, VStack } from "@@panda/jsx";
import { PricePlanCard } from "components/display/PricePlanCard/PricePlanCard";
import { Typography } from "components/display/Typography/Typography";
import { LoadingBanner } from "components/feedback/LoadingBanner/LoadingBanner";
import { FormSubLabel } from "components/forms/Form/FormSubLabel/FormSubLabel";
import { FeesTable } from "screens/create-deal/steps/Pricing/components/FeesTable";
import { validateActivePricePlan } from "screens/create-deal/steps/SelectSyndicate/components/PricePlans/utils";

import { usePlatformAndAdditionalFees } from "./usePlatformAndAdditionalFees";

export function PlatformAndAdditionalFees() {
  const { selectedPricePlan, currency } = usePlatformAndAdditionalFees();

  if (!selectedPricePlan || !currency) {
    return <LoadingBanner />;
  }

  const isActivePlan = validateActivePricePlan(selectedPricePlan);

  return (
    <VStack gap="2rem" alignItems="start" w="full">
      <PricePlanCard
        readonly
        pricePlan={selectedPricePlan}
        currency={currency}
        w="full"
      />

      {isActivePlan.ok && (
        <VStack gap="1rem" alignItems="start">
          <Box>
            <Typography variant="heading.3">
              Additional fees that may apply
            </Typography>
            <FormSubLabel>
              Any additional fees that apply will be deducted from total funds
              received.
            </FormSubLabel>
          </Box>
          <FeesTable pricePlan={isActivePlan.plan} currency={currency} />
        </VStack>
      )}
    </VStack>
  );
}
