import { css, cx } from "@@panda/css";
import { Box, HStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { FileUpload } from "components/interaction/FileUpload/FileUpload";
import { Variants, tabsRecipe } from "components/main/Tabs/styles";
import { useState } from "react";

import { inputMaxWidth } from "../../MemoMaterialsStep";
import { getNameAndLabel } from "../../utils";

const tabsStyles = tabsRecipe({ visual: Variants.Compact });

const pillButtonStyles = cx(
  tabsStyles.tab,
  css({
    cursor: "pointer",
    borderColor: "#383838ab!",
  })
);

export function InvestmentAgreementField() {
  const [mode, setMode] = useState<"text" | "file">("file");

  const urlSubLabel = (
    <>
      Please ensure the document is not blank and can be viewed by anyone who
      has the link.
    </>
  );

  const fileSubLabel = <>File must be in PDF format.</>;

  return (
    <Box w="full">
      <HStack
        justifyContent="space-between"
        alignItems="center"
        w="full"
        mb="s"
      >
        <Typography variant="body" fontSize="md">
          Investment agreement
        </Typography>
        <HStack justifyContent="flex-start" alignItems="center" w="auto!">
          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "file"}
            onClick={() => setMode((m) => (m === "file" ? "text" : "file"))}
            flexShrink={0}
          >
            File
          </Box>
          <Box
            className={pillButtonStyles}
            role="button"
            aria-selected={mode === "text"}
            onClick={() => setMode((m) => (m === "file" ? "text" : "file"))}
            flexShrink={0}
          >
            Link
          </Box>
        </HStack>
      </HStack>
      {mode === "text" ? (
        <Form.URLField
          {...getNameAndLabel("investment_agreement")}
          label="Public link*"
          width={["full", inputMaxWidth]}
          subLabel={urlSubLabel}
        />
      ) : (
        <FileUpload
          {...getNameAndLabel("investment_agreement")}
          label=""
          subLabel={fileSubLabel}
          singleFileMode
        />
      )}
    </Box>
  );
}
