import { Box, Flex } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { Radio } from "components/display/Radio";
import { Typography } from "components/display/Typography/Typography";

import { PricePlanCardProps } from "../PricePlanCard";
import {
  PricePlanColors,
  PricePlanValues,
  getLabelFromPricePlan,
} from "../utils";

type Props = Omit<PricePlanCardProps, "variant">;

export function LegacyPricePlanCard({
  currency = BaseCurrency.gbp,
  disabled = false,
  onClick,
  pricePlan = PricePlanName.core_2023,
  readonly = false,
  selected = false,
  ...rest
}: Props) {
  const actualCurrency = currency === "usdc" ? "usd" : currency;
  const pricePlanValues = PricePlanValues[pricePlan];
  const pricePlanPrices = pricePlanValues.prices[actualCurrency];

  return (
    <Box
      data-testid={`price-plan-item-${pricePlan}`}
      data-selected={selected}
      onClick={
        onClick && !disabled && !readonly ? () => onClick(pricePlan) : undefined
      }
      cursor={disabled ? "not-allowed" : "pointer"}
      pointerEvents={disabled ? "none" : "auto"}
      p="m"
      borderWidth="2px"
      borderStyle="solid"
      borderColor="grey.minecraft"
      borderRadius="md"
      height="full"
      transition="all 0.25s"
      {...(selected ? { "aria-selected": "true" } : null)}
      {...rest}
    >
      <Flex justifyContent="space-between" mb="1">
        <Typography
          variant="subtext"
          letterSpacing="0.11em"
          textTransform="uppercase"
          fontWeight="medium"
          style={{ color: token(PricePlanColors[pricePlan]) }}
        >
          {getLabelFromPricePlan(pricePlan)}
        </Typography>
        {!readonly && (
          <Radio
            data-testid={`price-plan-item-radio-${pricePlan}`}
            checked={selected}
            name={pricePlan}
            value={pricePlan}
          />
        )}
      </Flex>
      <Flex alignItems="flex-end" mb="1">
        {pricePlanPrices.oldPrice ? (
          <Typography
            variant="body"
            fontSize="1.125rem"
            fontWeight="medium"
            mr="1"
            textDecoration="line-through"
          >
            {pricePlanPrices.oldPrice}
          </Typography>
        ) : null}
        <Typography
          variant="body"
          fontSize="1.125rem"
          fontWeight="medium"
          mr="1"
        >
          {pricePlanPrices.price || "unknown"}
        </Typography>
      </Flex>
      {pricePlanValues.plusPercentageFundsRaised === null ? (
        <Typography
          variant="body"
          fontSize="sm"
          fontWeight="medium"
          lineHeight="5"
          color="grey.gunsmoke"
        >
          This is the total Platform Fee, to be paid after deal close.
        </Typography>
      ) : (
        <Typography
          variant="body"
          fontSize="sm"
          fontWeight="medium"
          lineHeight="5"
          color="grey.gunsmoke"
          mb={pricePlanPrices.hint ? "0" : "2"}
        >
          Plus {pricePlanValues?.plusPercentageFundsRaised}% of funds raised.
          Capped at {pricePlanValues.prices[actualCurrency].cappedAt || ""}
        </Typography>
      )}
      {pricePlanPrices.hint ? (
        <Typography variant="body" color="brand.fauve" mb="2">
          {pricePlanPrices.hint}
        </Typography>
      ) : null}
      {pricePlanValues.plusPercentageFundsRaised === null ? null : (
        <Typography
          variant="body"
          fontSize="sm"
          fontWeight="medium"
          lineHeight="5"
        >
          {pricePlanValues.description || ""}
        </Typography>
      )}
    </Box>
  );
}
