/* eslint-disable no-console */
import { css } from "@@panda/css";
import { Box, HStack, VStack } from "@@panda/jsx";
import { FileUpload as ArkFileUpload } from "@ark-ui/react";
import {
  ArrowUpCircleIcon,
  DocumentIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { FormLabel } from "components/forms/Form/FormLabel/FormLabel";
import { FormSubLabel } from "components/forms/Form/FormSubLabel/FormSubLabel";

// https://ark-ui.com/react/docs/components/file-upload

type Props = {
  singleFileMode?: boolean;
  label?: string;
  name?: string;
  subLabel?: string | React.ReactNode;
  dropzoneSublabel?: string;
};

export function FileUpload(props: Props) {
  return (
    <ArkFileUpload.Root
      maxFiles={5}
      allowDrop
      onFileAccept={(f) => console.log("onFileAccept", f)}
      onFileChange={(f) => console.log("onFileChange", f)}
      onFileReject={(f) => console.log("onFileReject", f)}
    >
      <ArkFileUpload.Context>
        {({ acceptedFiles }) => {
          const hasFiles = !!acceptedFiles.length;

          const showUploadZone =
            props.singleFileMode && hasFiles ? false : true;

          return (
            <>
              <Box>
                {props.label && (
                  <ArkFileUpload.Label asChild>
                    <FormLabel
                      htmlFor={props.name}
                      withSubLabel={Boolean(props.subLabel)}
                    >
                      {props.label}
                    </FormLabel>
                  </ArkFileUpload.Label>
                )}

                {props.subLabel && (
                  <FormSubLabel>{props.subLabel}</FormSubLabel>
                )}
              </Box>

              {showUploadZone && (
                <ArkFileUpload.Dropzone className={s.dropzone}>
                  <ArkFileUpload.Trigger className={s.trigger}>
                    <UploadIcon />

                    <VStack gap={"0.25rem"}>
                      <p>
                        <strong> Click to upload </strong> or drag and drop
                      </p>

                      {props.dropzoneSublabel && (
                        <p
                          className={css({
                            fontSize: "12px",
                            color: "grey.gunsmoke",
                          })}
                        >
                          {props.dropzoneSublabel}
                        </p>
                      )}
                    </VStack>
                  </ArkFileUpload.Trigger>
                </ArkFileUpload.Dropzone>
              )}

              <ArkFileUpload.ItemGroup className={s.files}>
                {acceptedFiles.map((file) => (
                  <ArkFileUpload.Item
                    key={file.name}
                    file={file}
                    className={s.file}
                  >
                    {/* <ArkFileUpload.ItemPreview type="image/*">
                    <ArkFileUpload.ItemPreviewImage />
                  </ArkFileUpload.ItemPreview>*/}

                    <HStack gap="1rem">
                      <ArkFileUpload.ItemPreview type=".*">
                        <DocumentIcon
                          className={css({ w: "20px", h: "20px" })}
                        />
                      </ArkFileUpload.ItemPreview>
                      <ArkFileUpload.ItemName />
                    </HStack>
                    {/* <ArkFileUpload.ItemSizeText /> */}

                    <ArkFileUpload.ItemDeleteTrigger>
                      <XCircleIcon className={css({ w: "20px", h: "20px" })} />
                    </ArkFileUpload.ItemDeleteTrigger>
                  </ArkFileUpload.Item>
                ))}
              </ArkFileUpload.ItemGroup>

              <ArkFileUpload.HiddenInput />
            </>
          );
        }}
      </ArkFileUpload.Context>
    </ArkFileUpload.Root>
  );
}

const s = {
  files: css({
    display: "flex",
    flexDir: "column",
    w: "full",
    gap: "1rem",
  }),
  file: css({
    display: "flex",
    flexDir: "row",
    w: "full",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "md",
    p: "1rem",
    borderColor: "grey.dune",
    borderWidth: "1px",
    "&:first-child": {
      mt: "1rem",
    },
  }),
  dropzone: css({
    w: "100%",
    h: "100%",
    height: "200px",
    transition: "all 0.2s",
    backgroundColor: "grey.woodsmoke",

    "[data-dragging] &": {
      backgroundColor: "grey.dune",
    },
    _hover: {
      backgroundColor: "grey.dune",
    },
  }),
  trigger: css({
    w: "100%",
    h: "100%",
    borderStyle: "dashed",
    borderColor: "grey.dune",
    borderRadius: "md",
    borderWidth: "1px",
    display: "flex",
    flexDir: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.75rem",
  }),
  label: css({
    marginBottom: "1rem",
  }),
  icon: css({
    w: "48px",
    h: "48px",
  }),
};

function UploadIcon() {
  return (
    <Box aria-hidden className={css({ position: "relative" })}>
      <DocumentIcon
        className={css({
          w: "48px",
          h: "48px",
        })}
      />
      <ArrowUpCircleIcon
        className={css({
          color: "brand.yamcha",
          position: "absolute",
          right: 0,
          bottom: 0,
          w: "20px",
          h: "20px",
        })}
      />
    </Box>
  );
}
