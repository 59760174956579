import {
  PricePlanName,
  SubscriptionTier,
} from "@internal/rest/generated/schemas";

export function isSubscribed(tier: SubscriptionTier): boolean {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
      return false;
    case SubscriptionTier.CORE_2023:
    case SubscriptionTier.HEADLINER_2023:
      return true;
    default:
      return false as never;
  }
}

export function mapSubscriptionToPricePlan(
  tier: SubscriptionTier
): PricePlanName {
  switch (tier) {
    case SubscriptionTier.BASE_2023:
      return PricePlanName.base_2023;
    case SubscriptionTier.CORE_2023:
      return PricePlanName.core_2023;
    case SubscriptionTier.HEADLINER_2023:
      return PricePlanName.headliner_2023;
    default:
      return PricePlanName.base_2023 as never;
  }
}
