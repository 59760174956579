import {
  LegalEntityInfoBusiness,
  LegalEntityInfoIndividual,
  LegalEntityTypeUnion,
} from "@internal/rest/generated/schemas";

interface GetLegalEntityNameArgS {
  isLargeScreen: boolean;
  rawEntity: LegalEntityTypeUnion;
}

export function getLegalEntityName({
  rawEntity,
  isLargeScreen,
}: GetLegalEntityNameArgS) {
  const charsLimit = isLargeScreen ? 35 : 20;

  if (rawEntity.type === "business") {
    const entity = rawEntity as LegalEntityInfoBusiness;
    const entityName = entity.company_legal_name;
    return entityName.length > charsLimit
      ? entityName.slice(0, charsLimit).concat("...")
      : entityName;
  }

  const entity = rawEntity as LegalEntityInfoIndividual;
  const withName = entity.first_name && entity.last_name;

  const name = withName
    ? `${entity.first_name} ${entity.last_name}`
    : entity.email;

  return name.length > charsLimit
    ? name.slice(0, charsLimit).concat("...")
    : name;
}

export function computeDefaultCarry(carry: number) {
  if (!carry) return "no carry";
  if (carry > 100) return "...";
  return `${carry}%`;
}

export function computeCarryShare(carry: number) {
  if (!carry) return "";
  if (carry > 100) return "...";
  return `${carry}%`;
}
