import { SyndicateLeadReference } from "@internal/rest/generated/schemas";
import { useDialog } from "components/main/Dialog/utils";

export enum FormFields {
  FirstName = "first_name",
  LastName = "last_name",
  Email = "email",
}

export enum FormLabels {
  FirstName = "First name",
  LastName = "Last name",
  Email = "Email",
}

interface Args {
  onAdd: (member: SyndicateLeadReference) => void;
}

export function useAddMemberForm({ onAdd }: Args) {
  const { onClose } = useDialog();

  function onSubmit(values: SyndicateLeadReference) {
    onAdd(values);
    onClose();
  }

  return {
    onClose,
    onSubmit,
  };
}
