import { Form } from "components/forms/Form/Form";

import { FormFields } from "../../utils";
import { SEISValues } from "./utils";

export function SEISFields() {
  return (
    <Form.CheckboxGroup
      name={FormFields.SEIS}
      label="Will investors be able to claim S/EIS relief?"
      subLabel="Odin does not review Advance Assurances as part of our review."
      options={[
        {
          value: SEISValues.SEIS,
          label: "SEIS",
        },
        {
          value: SEISValues.EIS,
          label: "EIS",
        },
      ]}
    />
  );
}
