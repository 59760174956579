import { useSyndicatesGet } from "@internal/rest/generated/queries/syndicates";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { CREATE_NEW_SYNDICATE_OPTION, FormFields } from "../../utils";

export function useLegalNameField() {
  const { setValue, watch } = useFormContext();
  const currentSyndicate = watch(FormFields.Syndicate);

  const isCreatingSyndicate = currentSyndicate === CREATE_NEW_SYNDICATE_OPTION;

  const syndicateResult = useSyndicatesGet(currentSyndicate, {
    query: {
      enabled: Boolean(currentSyndicate) && !isCreatingSyndicate,
    },
  });

  const selectedSyndicate = syndicateResult.data;
  const currentLegalName = selectedSyndicate?.legal_name;
  const isMutable = selectedSyndicate?.legal_name_mutability === "mutable";

  useEffect(() => {
    if (currentLegalName && isMutable && !isCreatingSyndicate) {
      setValue(FormFields.EntityLegalName, currentLegalName);
    }
  }, [currentLegalName, isMutable]);

  return {
    ready:
      isCreatingSyndicate ||
      (syndicateResult.isSuccess && Boolean(currentSyndicate)),
  };
}
